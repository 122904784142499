<template>
  <ag-card-container
    v-if="value"
    :title="`Edition de la référence ${value.name}`"
    fluid
    no-content-padding
    back="/part-number"
  >
    <template #extension>
      <v-tabs
        :slider-size="5"
        slider-color="accent"
        grow
      >
        <v-tab
          :to="`/part-number/${partNumberId}/details`"
          exact
          @click="search = ''"
          v-text="'Details'"
        />
        <v-tab
          :to="`/part-number/${partNumberId}/routing`"
          exact
          @click="search = ''"
          v-text="'Gamme'"
        />
      </v-tabs>
    </template>
    <router-view />
  </ag-card-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgCardContainer from 'src/components/common/AgCardContainer'

export default {
  name: 'PartNumberUpdateContainer',
  components: {
    AgCardContainer,
  },
  props: {
    partNumberId: {
      type: String,
      required: true,
    },
  },
  beforeRouteEnter (to, from, next) {
    if (to.name === 'PartNumberUpdate') {
      next(vm => next(`/part-number/${vm.$props.partNumberId}/details`))
    }
    else {
      next()
    }
  },
  data: () => ({
    value: null,
    tab: null,
  }),
  watch: {
    $route: {
      immediate: true,
      async handler () {
        try {
          this.value = await Repositories.partNumber.get(this.partNumberId)
        }
        catch (error) {
          this.showErrorNotification(Repositories.parseErrorMessage(error))
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
  },
}
</script>
